import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/hero'

const GatsbyLogo = require('../../svgs/tools/gatsby.svg')
const LaravelLogo = require('../../svgs/tools/laravel.svg')
const ReactLogo = require('../../svgs/tools/react-logo.svg')
const VueLogo = require('../../svgs/tools/vue.svg')
const WordpressLogo = require('../../svgs/tools/wordpress.svg')

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "developer.jpg" }) {
      ...heroImage
    }
  }
`
const LaravelDevelopmentPage = () => (
  <Layout>
    <SEO
      title="Laravel Development in Brighton | amillionmonkeys"
      description="Looking for a friendly Laravel developer in Brighton or London? It's our bread and butter. Get in touch to see how we can help you."
    />
    <StaticQuery
      query={query}
      render={data => (
        <React.Fragment>
          <Hero image={data.hero.childImageSharp.fluid}>
            <div className="max-w-md m-auto">
              <p className="text-3xl text-white text-center  font-serif text-shadow">
                At amillionmonkeys we use Laravel to build production quality applications that are robust, easy to
                maintain, secure and well tested.
              </p>
            </div>
          </Hero>
          <div className="bg-laravel bg-stamp text-300 text-white py-16  px-4  pb-24 ">
            <div className=" text-2xl font-serif font-light text-center">
              <p className="mb-4 max-w-lg m-auto">
                <strong>
                  The web-apps and APIs we've built with Laravel are used by thousands of people everyday. These include{' '}
                </strong>
              </p>
              <ul className="md:flex list-reset text-base max-w-2xl m-auto">
                <li className="p-4">
                  <h3 className="font-impact text-4xl ">eightfourtwo</h3>
                  <p>
                    When leading wealth maangement company eightfourtwo couldn't find a CRM that matched their needs
                    they asked us to build them something bespoke. Over the past five years, our application grown to
                    become the bedrock of their business and is used daily by employees and customers alike.{' '}
                  </p>
                </li>
                <li className="p-4">
                  <h3 className="font-impact text-4xl ">Sussex Wildlife Trust</h3>
                  <p>
                    Alongside their website, Sussex Wildlife Trust required a bespoke onboarding process for new
                    members. Using VueJS alongside Laravel, we're able to offer a great user experience that has
                    resulted in a marked increase in conversions.
                  </p>
                </li>
                <li className="p-4">
                  <h3 className="font-impact text-4xl ">Goodman Masson</h3>
                  <p>
                    Leading tech recruitment agency Goodman Masson approached amillionmonkeys to help with modernise
                    their candidate onboarding. Laravel allowed us to rapidly build an application that sends
                    notifications, generates emails and securely store data.{' '}
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="bg-grey-lighter bg-stamp text-300 text-grey-darkest py-16  px-4  pb-24 ">
            <div className="m-auto max-w-lg text-2xl font-serif font-light text-center">
              <h2 className="font-impact  text-4xl">Let's talk</h2>
              <p>Whether you've got an idea, a problem, or just need another pair of hands, we'd love to talk.</p>
              <Link
                to="/contact"
                className="font-impact  text-3xl text-laravel border-laravel border-2 p-3 text-lg inline-block my-4"
              >
                Get in touch
              </Link>
            </div>
          </div>
        </React.Fragment>
      )}
    />
  </Layout>
)

export default LaravelDevelopmentPage
